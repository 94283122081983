
<template lang='pug'>
   vx-card
    //*** REMOVE POP UP
    vs-row(vs-w="12", style="heigth:100px;")
      vs-col(style="padding-right:0; ", vs-type="flex", vs-align="center", vs-lg="8", vs-sm="12", vs-xs="12")
        vs-row(vs-w="12")
          vs-col(vs-lg="3", vs-sm="3", vs-xs="12", vs-align="center", style="padding: 8px 10px;  vertical-align: middle; font-size:14px;")
            span เปิดรายการเช่า :
          vs-col(vs-align="center", style="padding: 8px 10px;  vertical-align: middle; font-size:16px; font-weight: bold;", vs-lg="4", vs-sm="4", vs-xs="12") {{thisordno(this.orderNumber)}}
          vs-col(vs-type="flex", vs-align="flex-end", vs-lg="4", vs-sm="4", vs-xs="12")
            vs-input(autofocus="", style="width:100%;", icon-after="true", icon="shopping_cart", label-placeholder="", ref="addcart", @click="setSelect($event)", v-model="addtocart")
        vs-row(vs-w="12")
          ul.demo-alignment(v-if="$acl.check('admin')")
            span.sm-btn-green.px-1(style="font-size: 16px; font-weight: bold;") สาขา : {{thisBranch.branchName}}
      //- vs-col(v-if="$acl.check('admin')", style="padding-left:0;", vs-type="flex", vs-align="flex-start", vs-lg="2", vs-sm="2", vs-xs="12")
      //-   br
      //- vs-col(v-else="", style="padding-left:0; margin-top:10px;", vs-type="flex", vs-align="flex-start", vs-lg="2", vs-sm="2", vs-xs="12")
      //-   vs-button(href="/backoffice/rental", target="blank", style="width:100%; padding: 10px 0;", color="primary", type="filled") เปิดรายการใหม่
      vs-col(style="padding-left:0;", vs-type="flex", vs-align="center", vs-lg="4", vs-sm="12", vs-xs="12")
        .grid-layout-container.alignment-block(style="width:100%;")
          vs-row(vs-align="flex-start", vs-type="flex", vs-justify="center", vs-w="12")
            // <vs-col vs-w="2"></vs-col>
            vs-col(style="color:#3545B7; font-weight:bold; font-size: 18px; text-align:right;", vs-w="5") ราคาเช่าสินค้า :
            vs-col(style="color:#3545B7; font-weight:bold; font-size: 18px;", vs-w="6") {{formatPrice(rentalPriceTotal)}} บาท
          vs-row(vs-align="flex-start", vs-type="flex", vs-justify="center", vs-w="12")
            vs-col(style="color:#30B717; font-weight:bold; font-size: 18px; text-align:right;", vs-w="5") เงินประกัน :
            vs-col(style="color:#30B717; font-weight:bold; font-size: 18px;", vs-w="6") {{formatPrice(bailTotal)}} บาท
          vs-row(vs-align="flex-start", vs-type="flex", vs-justify="center", vs-w="12")
            vs-col(style="color:#4c4c4c; font-weight:bold; font-size: 18px; text-align:right;", vs-w="5") ยอดเงินรวม :
            vs-col(style="color:#4c4c4c; font-weight:bold; font-size: 18px;", vs-w="6") {{formatPrice(grandTotal)}} บาท
          vs-row(vs-w="12")
            vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="4", vs-sm="4", vs-xs="12")
              img(v-if="this.pro === '1'", style="width:120px;", :src="$store.state.defaultStore + 'buy1get1.png'")
              img(v-else-if="this.pro === '2'", style="width:120px;", :src="$store.state.defaultStore + 'buy2get1.png'")
              img(v-else-if="this.pro === '3'", style="width:180px;", :src="$store.state.defaultStore + 'step_discount.png'")
              div(v-if="this.pro === '3'", style="position:absolute; color: white; font-weight:bold; font-size: 10px; margin-left: 60px; ") เช่า {{proDetail.startAmount}} ลด {{proDetail.discount}} %
              // <img v-else style="width:120px;" :src="$store.state.defaultStore + 'buy2get1.png'" />
    p
    br
    // รายการใหม่
    .vs-row(style="display: flex; width: 100%; margin-top: 30px;")
      div(style="width: 100%; height: 5px; ")
    h3(style="font-family: SukhumvitSet-Medium;") รายการใหม่
    .vs-row(style="display: flex; width: 100%; margin-top: 30px;")
      div(style="width: 100%; height: 5px; background-color: rgb(122, 231, 255);")
    // ตาราง
    vs-table(hoverflat="", stripe="", :data="displayItems")
      template(slot="thead")
        vs-th รูปตัวอย่าง
        vs-th(style="width:200px;", sort-key="itemName") ชื่อสินค้า
        vs-th(sort-key="reservedQuantity") จำนวน
        vs-th(style="text-align:center;", sort-key="rentalPrice") ราคาเช่า
        vs-th(style="text-align:center;", sort-key="bail") เงินประกัน
        vs-th การจัดการ
      template(slot-scope="{data}")
        vs-tr(:key="indextr", v-for="(tr, indextr) in data")
          vs-td
            img.responsive.rounded(:src="getImagepath(tr.mainImage)", alt="user-upload", style="width:100px;")
            br
            .notAvailable(style="width:70%;", v-show="avanew[indextr]") ติดคิว
          vs-td(:data="data[indextr].code")
            | {{data[indextr].name}}
            br
            span(style="font-size:0.8rem;") {{data[indextr].code}}
          vs-td(:data="data[indextr].reservedQuantity")
            .centerx
              vs-input-number(style="width:50px; color: red;", min="1", max="5", v-model="data[indextr].reservedQuantity")
          vs-td(:data="data[indextr].rentalPrice")
            span(style="padding:14px 10px;") {{formatPrice(data[indextr].rentalPrice * data[indextr].reservedQuantity)}}
          vs-td(:data="data[indextr].bail")
            span(style="padding:14px 10px;") {{formatPrice(data[indextr].bail * data[indextr].reservedQuantity)}}
          vs-td
            i.vs-icon.notranslate.icon-scale.vs-button--icon.material-icons.null(@click="removeItem(indextr)", style="order: 0;text-align:left;  font-size:25px; color:red;") delete_forever
    // รายการเดิม
    .vs-row(style="display: flex; width: 100%; margin-top: 30px;")
      div(style="width: 100%; height: 5px; ")
    h3(style="font-family: SukhumvitSet-Medium;") รายการเดิม
    .vs-row(style="display: flex; width: 100%; margin-top: 30px;")
      div(style="width: 100%; height: 5px; background-color: rgb(122, 231, 255);")
    // ตาราง
    vs-table(hoverflat="", stripe="", :data="displayItems2")
      template(slot="thead")
        vs-th รูปตัวอย่าง
        vs-th(style="width:200px;", sort-key="itemName") ชื่อสินค้า
        vs-th(sort-key="reservedQuantity") จำนวน
        vs-th(style="text-align:center;", sort-key="rentalPrice") ราคาเช่า
        vs-th(style="text-align:center;", sort-key="bail") เงินประกัน
        vs-th การจัดการ
      template(slot-scope="{data}")
        vs-tr(:key="indextr", v-for="(tr, indextr) in data")
          vs-td
            img.responsive.rounded(:src="getImagepath(tr.mainImage)", alt="user-upload", style="width:100px;")
            br
            .notAvailable(style="width:70%;", v-show="tr.notAvailable") ติดคิว
          vs-td(:data="data[indextr].code")
            | {{data[indextr].name}}
            br
            span(style="font-size:0.8rem;") {{data[indextr].code}}

            i.vs-icon.notranslate.icon-scale.vs-button--icon.material-icons.null(v-if="data[indextr].removed === 'y'", style="order: 0;text-align:left;  font-size:30px; color:red; cursor:pointer;") cancel
          vs-td(:data="data[indextr].reservedQuantity")
            .centerx
              vs-input-number(style="width:50px; color: red;", min="1", max="5", v-model="data[indextr].reservedQuantity")
          vs-td(:data="data[indextr].rentalPrice")
            span(style="padding:14px 10px;") {{formatPrice(data[indextr].rentalPrice * data[indextr].reservedQuantity)}}
          vs-td(:data="data[indextr].bail")
            span(style="padding:14px 10px;") {{formatPrice(data[indextr].bail * data[indextr].reservedQuantity)}}
          vs-td
            i.vs-icon.notranslate.icon-scale.vs-button--icon.material-icons.null(v-if="data[indextr].removed !== 'y'", @click="removeFlag(indextr)", style="order: 0;text-align:left;  font-size:25px; color:red; cursor: pointer") delete_forever
            .sm-btn-black(v-if="data[indextr].removed === 'y'", @click="undoFlag(indextr)", style="cursor: pointer") ย้อนกลับ
    // <prism language="js" class="rounded-lg">{{ selected }}</prism>
    vs-row(vs-w="12", style="margin-top:30px; z-index: 9999 !important;")
      vs-col(style="padding-right:0; ", vs-type="flex", vs-align="center", vs-w="9")
        vs-row(vs-w="12" style="z-index: 9999 !important;")
          vs-col(vs-w="3", vs-align="center", style="padding: 8px 10px;  vertical-align: middle; font-size:14px;")
            span กำหนดวันรับ-คืนชุด :
          vs-col(vs-w="5" class="px-2" vs-type="flex", vs-align="center" vs-justify="flex-start")
            v-date-picker(  :key="datecode" readonly="", style="width:100%;font-size:14px;", :locale="{id: 'th', firstDayOfWeek: 2, masks: { L: 'DD/MM/YYYY' }}", mode="range", :min-date="new Date()", v-model="rentalDate"  )
            //- v-date-picker( class="cal" ref="theref" v-if="editCalendar" :key="datecode" readonly="", style="width:0; padding 0; font-size:14px;", :locale="{id: 'th', firstDayOfWeek: 2, masks: { L: 'DD/MM/YYYY' }}", mode="range", :min-date="new Date()", v-model="rentalDate"  v-on:selected="doSelection()" )
            //- i.material-icons.iconhover( @mouseover="$refs.theref.mouseover()" style="margin-left: 20px; color: pink; background-color: #ffefd1;  cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; ") edit
            //- vx-tooltip(v-if="!editCalendar" text="แก้ไขวันรับ-คืนชุด", position="top")
            //-   i.material-icons.iconhover( v-if="!editCalendar" @click="reviseCalendar()", style="margin-left: 20px; color: orange; background-color: #ffefd1;  cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; ") edit
          vs-col(vs-w="3")

            vs-button(@click="checkQueueAll()", style="width:100%; padding: 10px 0; ", color="success", type="filled") เช็คคิวจอง

          //-   datepicker(style="width:100%;", placeholder="วันเริ่มจอง", format="yyyy-MM-dd", v-model="startDate")
          //- vs-col(vs-w="5")
          //-   datepicker(style="width:100%;", placeholder="วันสุดท้ายที่จอง", format="yyyy-MM-dd", v-model="endDate")
      //- vs-col(style="padding-left:0;", vs-type="flex", vs-align="center", vs-w="2")
      //-   vs-button(@click="checkQueueAll()", style="width:100%; padding: 10px 0;", color="success", type="filled") เช็คคิวจอง
        // <vs-button color="dark" type="filled">Dark</vs-button>
      vs-col(style="padding-left:0;", vs-type="flex", vs-align="center", vs-w="3")
        //
          <vs-checkbox disabled="disabled" v-model="checkRentalList">
          <span style="font-weight:bold; color:red;">ตรวจสอบรายการเช่าชุดถูกต้อง</span>
          </vs-checkbox>
        vs-checkbox(v-if="activeBtn===true" v-model="checkRentalList" )
          span(style="font-weight:bold; color:red;") ยืนยันดำเนินการต่อ
    // -----------------------------------------------------------------
    vs-row(vs-w="12", style="margin-top:30px;")
      div(style="width:100%; height:5px; background-color:#7AE7FF;")
    // -----------------------------------------------------------------
    .grid-layout-container.alignment-block(v-if="checkRentalList", style="vertical-align:text-top ;width:100%;")
      // รายละเอียดผู้เช่า
      vs-row(vs-w="12", style="margin-bottom:30px; margin-top:30px;")
        vs-col(vs-align="flex-start", vs-type="flex", vs-lg="2", vs-sm="4", vs-xs="4")
          h3 รายละเอียดผู้เช่า

      //*** BEGIN รายละเอียดผู้เช่า แบบ POPUP
      //- vs-popup(title="รายละเอียดผู้เช่า", :active.sync="popupOnlineBillingActive", :button-close-hidden="true")
      vs-row
        vs-col(vs-w="12")
          vs-row
            vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="12", vs-md="2", vs-lg="3") เบอร์ติดต่อ
            vs-col(vs-w="10", vs-sm="12", vs-md="8", vs-lg="6")
              vs-input.w-full(@keypress.enter="getCustomerInfo(customerInfo.phoneNumber)", v-model="customerInfo.phoneNumber")
            vs-col.flex.justify-end.items-center(vs-w="2")
              vs-button(@click="getCustomerInfo(customerInfo.phoneNumber)", color="success")
                font-awesome-icon(icon="user", style="color: #ffffff")
          vs-row.mt-4
            vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="12", vs-md="2", vs-lg="3") ชื่อลูกค้า
            vs-col(vs-w="12", vs-sm="12", vs-md="8", vs-lg="6")
              vs-input.w-full(v-model="customerInfo.name")
          vs-row.mt-4
            vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="12", vs-md="2", vs-lg="3") ที่อยู่
            vs-col(vs-w="12", vs-sm="12", vs-md="8", vs-lg="6")
              vs-input.w-full(v-model="customerInfo.address")
          vs-row.mt-4(v-if="modalExpand == 'none'")
            vs-col.text-center(vs-w="4")
              font-awesome-icon(icon="coins")
              | แต้มคะแนน
              span.text-xl.font-bold {{this.formatPrice(customerInfo.point)}}
            vs-col.text-right(vs-w="6") มูลค่า Voucher
              span.text-xl.font-bold {{this.formatPrice(customerInfo.voucher)}}
              br
          div(:style="{ display: modalExpand }")
            vs-row.mt-4
              vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="12", vs-md="2", vs-lg="3") Line ID
              vs-col(vs-w="12", vs-sm="8", vs-md="8", vs-lg="6")
                vs-input.w-full(v-model="customerInfo.lineId")
            vs-row.mt-4
              vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="12", vs-md="2", vs-lg="3") วันเกิด
              vs-col(vs-w="12", vs-sm="8", vs-md="8", vs-lg="6")
                v-date-picker(v-model="customerInfo.birthDate", :locale="{ id: 'th', firstDayOfWeek: 2, masks: { L: 'DD/MM/YYYY' } }")
            vs-row.mt-4
              vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="12", vs-md="2", vs-lg="3") รู้จักได้ยังไง
              vs-col(vs-w="12", vs-sm="8", vs-md="8", vs-lg="6")
                select.w-full.px-2.py-3.rounded(v-model="customerInfo.howToKnow", style="border: 1px solid #e0e0e0")
                  option(value="เว็บไซต์") เว็บไซต์
                  option(value="Facebook") Facebook
                  option(value="Google Ads") Google Ads
                  option(value="บอกต่อ") บอกต่อ
            vs-row.mt-4
              vs-col.text-center(vs-w="4")
                font-awesome-icon(icon="coins")
                | แต้มคะแนน
                span.text-xl.font-bold {{this.formatPrice(customerInfo.point)}}
              vs-col.text-center(vs-w="6") มูลค่า Voucher
                span.text-xl.font-bold {{this.formatPrice(customerInfo.voucher)}}
                br
                span.text-danger Expired
          vs-row.mt-4
            vs-col(vs-w="12")
              .flex.justify-center.items-center
                vs-button.c-box-shadow(icon, style="background-color: transparent !important; color: #cccccc", @click="modalExpandToggle")
                  font-awesome-icon(:icon=" modalExpand == 'none' ? 'chevron-down' : 'chevron-up' ", size="lg")
          .mt-4.mb-8
            hr
          vs-row.mt-4
            vs-col.mb-4(vs-w="12")
              vs-row
                vs-col(vs-w="9")
                  vs-row
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") ใช้แต้มคะแนน
                    vs-col(v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'", vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      CurrencyValue(style="width:80%;", v-model="usePoint")
                    vs-col.pr-4(v-else-if="$store.state.auth.currentPackage ==='s'", vs-type="flex", vs-justify="flex-start", vs-align="center", vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      .px-1.label-btn-yellow(style="text-align:center; width:100%; height:100%; padding-top: 7px; padding-bottom: 7px; border-radius:5px;") อัพเกรด Package
                vs-col(vs-w="3")
                  span.pr-4 คงเหลือ
                  span.text-xl.font-bold.ml-4(v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'") {{this.formatPrice(customerInfo.point)}}
                  h3(v-else-if="$store.state.auth.currentPackage ==='s'") * * * * *
            vs-col.mb-4(vs-w="12")
              vs-row
                vs-col(vs-w="9")
                  vs-row
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") ใช้ Voucher
                    vs-col(v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'", vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      CurrencyValue(style="width:80%;", v-model="useVoucher")
                    vs-col.pr-4(v-else-if="$store.state.auth.currentPackage ==='s'", vs-type="flex", vs-justify="flex-start", vs-align="center", vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      .px-1.label-btn-yellow(style="text-align:center; width:100%; height:100%; padding-top: 7px; padding-bottom: 7px; border-radius:5px;") อัพเกรด Package
                vs-col(vs-w="3")
                  span.pr-4 คงเหลือ
                  span.text-xl.font-bold.ml-4(v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'") {{this.formatPrice(customerInfo.voucher)}}
                  h3(v-else-if="$store.state.auth.currentPackage ==='s'") * * * * *
            vs-col.mb-4(vs-w="12")
              vs-row
                vs-col(vs-w="9")
                  vs-row
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") ส่วนลดเงินประกัน
                    vs-col(vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      CurrencyValue#bailDiscount.w-full(v-model="bailDiscount")
            vs-col.mb-4(vs-w="12")
              vs-row
                vs-col(vs-w="9")
                  vs-row
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") ส่วนลดค่าเช่า
                    vs-col(vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      CurrencyValue.w-full(v-model="rentalDiscount")
            vs-col.mb-4(vs-w="12")
              vs-row
                vs-col(vs-w="9")
                  vs-row
                    vs-col.flex.justify-center.items-center.mb-4.pb-4(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") ส่วนลดโปรโมชั่น
                    vs-col.mb-4(v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'", vs-w="12", vs-sm="8", vs-md="8", vs-lg="8") {{formatPrice2Point(promotionDiscount)}}
                      | (
                      | {{promotionDiscountDesc}}
                      | )
                    vs-col.pr-4.mb-4(v-else-if="$store.state.auth.currentPackage ==='s'", vs-type="flex", vs-justify="flex-start", vs-align="center", vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      .px-1.label-btn-yellow(style="text-align:center; width:100%; height:100%; padding-top: 7px; padding-bottom: 7px; border-radius:5px;") อัพเกรด Package
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") วิธีมารับ
                    vs-col.mb-4(vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      select.px-2.py-3.rounded(v-model="pickupMethod", style="border: 1px solid #e0e0e0; width: 200px")
                        option(v-for="(item, index) in walkinOptions", :key="`option-walkin-${index}`", :value="item") {{ item }}
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") วิธีชำระค่าเช่า
                    vs-col.mb-4(vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      select.px-2.py-3.rounded(v-model="rentalPaymentMethod", style="border: 1px solid #e0e0e0; width: 200px")
                        option(v-for="(item, index) in rentalPaymentOptions", :key="`option-rentalpayment-${index}`", :value="item") {{ item }}
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") วิธีชำระค่าประกัน
                    vs-col.mb-4(vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      select.px-2.py-3.rounded(v-model="bailPaymentMethod", style="border: 1px solid #e0e0e0; width: 200px")
                        option(v-for="(item, index) in bailPaymentOptions", :key="`option-bailpayment-${index}`", :value="item") {{ item }}
                    vs-col.mb-4.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") วันรับ ~ คืนชุด
                    vs-col.mb-4.flex.items-center(vs-w="12", vs-sm="8", vs-md="8", vs-lg="8")
                      b {{ format_ddmmyyy(startDate) }}
                        | ~
                        | {{ format_ddmmyyy(endDate) }}
                    vs-col.flex.justify-center.items-center(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4") โน้ต
                    vs-col(vs-w="12", vs-sm="4", vs-md="5", vs-lg="5")
                      vs-textarea(v-model="remark1")
                    vs-col(v-if="(this.orderStatus === 'รอรับชุด')", vs-type="flex", vs-justify="flex-end", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
                      vs-button(@click="hold()", color="danger", type="border", :disabled="btnActive" ) เก็บสิทธิ์
                    vs-col.mt-8(vs-w="12")
                      vs-row
                        //- vs-col(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4")
                        //- vs-col( vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
                        //-   vs-button(@click="hold()", color="warning", type="border", :disabled="btnActive" ) เก็บสิทธิ์
                        vs-col(vs-w="12", vs-sm="12", vs-md="12", vs-lg="12" , vs-type="flex", vs-justify="center")
                          vs-button(@click="prepareSubmitOrder()", :disabled="btnActive", color="success", style="width:150px;") บันทึก
                        //- vs-col(vs-w="12", vs-sm="4", vs-md="4", vs-lg="4")
                        //-   vs-button(color="danger", :disabled="btnActive", @click="closePopupOnlineBilling") ยกเลิก

      //*** END รายละเอียดผู้เช่า แบบ POPUP

      //- vs-row(vs-w="12")
      //- // ชื่อลูกค้า
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") ชื่อลูกค้า
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="5", vs-sm="5", vs-xs="6")
      //-     vs-input.w-full(v-model="customerName")
      //-       //
      //-         <span
      //-         class="text-danger text-sm"
      //-         v-show="errors.has('customerName')"
      //-         >{{ errors.first('customerName') }}</span>
      //- // ที่อยู่
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") ที่อยู่
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="5", vs-sm="5", vs-xs="6")
      //-     vs-textarea.w-full(v-model="customerAddress")
      //- // เบอร์ติดต่อ
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") เบอร์ติดต่อ
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="5", vs-sm="5", vs-xs="6")
      //-     vs-input.w-full(v-model="customerPhone")
      //- // -----------------------------------------------------------------
      //- div(style="border-top: 1px solid #E3E3E3; margin-bottom:30px; ")
      //- // -----------------------------------------------------------------
      //- // ส่วนลดเงินประกัน
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") ส่วนลดเงินประกัน
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
      //-     // <vs-input class="w-full" name="bailDiscount" v-model="bailDiscount"/>
      //-     CurrencyValue.w-full(v-model="bailDiscount")
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="2") บาท
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="2")
      //-     vs-input.w-full(@blur="computeBailDiscount($event.target.value)", name="bailDiscountPercent", @focus="selectAll($event)", v-model="bailDiscountPercent")
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="2") %
      //- // ส่วนลดค่าเช่า
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") ส่วนลดค่าเช่า
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
      //-     // <vs-input class="w-full" name="rentalDiscount" v-model="rentalDiscount"/>
      //-     CurrencyValue.w-full(v-model="rentalDiscount")
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="2") บาท
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="2")
      //-     vs-input.w-full(@blur="computeRentalDiscount($event.target.value)", @focus="selectAll($event)", name="rentalDiscountPercent", v-model="rentalDiscountPercent")
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="2") %
      //- // ส่วนลดโปรโมชั่น
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") ส่วนลดโปรโมชั่น
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
      //-     vs-input.w-full(style="background-color: #f4f4f4;", readonly="", name="promotionDiscount", v-model="promotionDiscount")
      //- // วันรับ
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") วันรับ
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
      //-     b {{formatDate(startDate)}}
      //- // วันคืน
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") วันคืน
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
      //-     b {{formatDate(endDate)}}
      //- // วิธีมารับ
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") วิธีมารับ
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="6")
      //-     ul.centerx
      //-       li
      //-         vs-radio(v-model="receivingMethod", vs-value="มารับเอง") มารับเอง
      //-       li
      //-         vs-radio(v-model="receivingMethod", vs-value="ผ่านเมสเซนเจอร์") ผ่านเมสเซนเจอร์
      //-       li
      //-         vs-radio(v-model="receivingMethod", vs-value="ผ่าน EMS / Kerry") ผ่าน EMS / Kerry
      //- // โน้ต
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") โน้ต
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="5", vs-sm="5", vs-xs="6")
      //-     vs-textarea.w-full(v-model="remark1")
      //- // วันลงชำระ
      //- vs-row.row-form(vs-w="12")
      //-   vs-col.form-label.px-2(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-offset="2", vs-lg="2", vs-sm="4", vs-xs="4") วันลงชำระ
      //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="4")
      //-     b {{toDate()}}
      //-   vs-col(v-if="(this.orderStatus === 'รอรับชุด')", vs-type="flex", vs-justify="flex-end", vs-align="center", vs-lg="3", vs-sm="4", vs-xs="6")
      //-     vs-button(@click="hold()", color="danger", type="filled", :disabled="btnActive" ) เก็บสิทธิ์
      //- // -----------------------------------------------------------------
      //- div(style="border-top: 1px solid #E3E3E3; margin-bottom:30px; ")
      //- // -----------------------------------------------------------------
      //- .grid-layout-container.alignment-block(style="width:100%;")
      //-   vs-row
      //-     // ปุ่ม
      //-     vs-col(vs-w="3", vs-type="flex", vs-justify="center")
      //-     vs-col(v-if="(this.orderStatus === 'รอรับชุด')", vs-lg="3", vs-sm="4", vs-xs="6", vs-type="flex", vs-justify="flex-end")
      //-       vs-button(@click="create()", color="success", type="filled", :disabled="btnActive" ) บันทึกการเช่า
      //-     // ปุ่ม
      //-     vs-col(v-if="(this.orderStatus === 'รอรับชุด')", vs-type="flex", vs-justify="flex-start")
      //-       // <vs-button @click="hold()" color="danger" type="filled">เก็บสิทธิ์</vs-button>
      //-     vs-col(vs-w="3", vs-type="flex", vs-justify="center")
      //- // -----------------------------------------------------------------

</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
// import { Validator } from "vee-validate";
import vSelect from "vue-select";

import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
import { setTimeout } from "timers";

// const dict = {
//   custom: {
//     name: {
//       required: "ยังไม่ได้ใส่ชื่อลูกค้า"
//     }
//   }
// };

// Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  data() {
    return {
      //************ BEGIN  15May2022 */

      customerInfo: {
        phoneNumber: '',
        name: '',
        address: '',
        lineId: '',
        birthDate: new Date('1800-01-01'),
        howToKnow: '',
        point: 0,
        voucher: 0,
        insuranceDiscount: '',
        insuranceDiscountPercent: '',
        rentalDiscount: '',
        rentalDiscountPercent: '',
        promotionDiscount: 0,
        promotionDiscountDesc: 0,
        paymentInsuranceType: '',
      },

      promotionDiscountDesc: 0,

      usePoint: 0,
      useVoucher: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      pickupMethod: '',
      rentalPaymentMethod: '',
      bailPaymentMethod: '',
      walkinOptions: ["มารับเอง", "Line Man", "Grab", "Kerry", "Flash", "ขนส่งอื่น"],
      rentalPaymentOptions: ["ยังไม่ชำระ","เงินสด", "โอนเงิน", "Alipay/wechat" , "บัตรเครดิต","ช่องทางอื่น"],
      bailPaymentOptions: ["ยังไม่ชำระ","เงินสด", "โอนเงิน", "Alipay/wechat" , "บัตรเครดิต","ช่องทางอื่น"],
      knowSelected: 1,
      walkinSelected: "เดินทางมารับเอง",
      // bailPaymentMethod: "",
      birthdate: "",
      modalExpand: "none",
      note: "",
      localeStringConfig: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      confirmSubmit: false,

      branchId: "",
      disableConfirmCheckbox: true,
      products: [],
      totalPaginate: 0,
      currentPaginate: 1,
      pagination: {},
      productTotal: 0,
      popupOnlineBillingActive: false,

      //************ END  15May2022 */

      btnActive: false,
      washingDate: 0,
      order:{},
      activeBtn: false,
      editCalendar: false,
      delayedDatepicker: false,
      datecode: 0,
      rentalDate: {},
      pro: "0",
      proDetail: {},
      ava: [],
      avanew: [],
      orderid: null,
      orderDetail: [],
      orderHeader: [],
      currentBranchId: "",
      itemsInBranch: [],
      originalList: [],
      branchStock: [],
      // >>>>>>>>>>>>>> for select branch
      branches: [],
      branchRadio: "",
      // >>>>>>>>>>>>>> for select branch
      currentUser: "guest",
      roles: [],
      checked: false,

      // 1. ORDER HEADER
      orderNumber: "",
      orderStatus: "", // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
      remark: "",
      remark1: "",

      // 2. CUSTOMER
      // customerInfo: "",
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      transferContact: "",

      // 3. DELIVERY
      howtoreceive: "มารับเอง", // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
      receivingDate: null,
      receivingMethod: "มารับเอง",
      returnDate: null,
      addtocart: "",
      // 4. MONETARY
      rentalPriceTotal: 0,
      bailTotal: 0,
      grandTotal: 0,
      // bailDiscount: 0,
      // rentalDiscount: 0,
      // promotionDiscount: 0,
      rentalDiscountPercent: 0,
      bailDiscountPercent: 0,
      deduction: 0,
      deducingDate: null,

      checkRentalList: false,
      startDate: null,
      endDate: null,
      orderInfo: {
        orderNumber: "ORD19031300042"
      },

      selected: [],
      select_order: { label: "OR1903130042", value: "OR1903130042" },
      order_option: [
        { label: "OR1903130042", value: "OR1903130042" },
        { label: "OR1903130041", value: "OR1903130041" },
        { label: "OR1903130040", value: "OR1903130040" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],

      displayItems: [],
      displayItems2: [],
      itemsInStock: [],

      customers: [],
      thisBranch: {}
    };
  },
  components: {
    Prism,
    Datepicker,
    CurrencyValue,
    "v-select": vSelect
  },

  computed: {
     //  *** BEGIN : 2021-01-19 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    },
     //  *** BEGIN : 2021-01-19 : Search By branches
    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.displayItems.length; i++) {
        total += this.displayItems[i].rentalPrice;
      }
      return total;
    },
    //  *** BEGIN : 2020-04-06 : TAX INFO
    taxInfo(){
      return this.$store.getters["shop/tax"];
    },
  },


  async created() {
    this.orderid = this.$route.params.orderid;
       this.currentUser = JSON.parse(localStorage.getItem("username"));
    var thisBranch = null;

    //*** 2021-04-14 */

     var setting = null;
    try {
        setting = await this.$http.post(
            this.$store.state.apiURL + "/api/shopinfo/get-clean-fix-duration",
            {},
            this.$store.state.auth.apiHeader
          );

          // console.log("setting >>> ",setting.data);

          this.washingDate = setting.data.cleanFixDuration;

    } catch (error) {
      // setting = null;

    }

    // this.rentalDate.start = new Date();
    // this.rentalDate.end = new Date();
    // console.log("ELLELLLLOOOO ::::::::::: this.orderid >>> ", this.orderid);


    // IF EVER COME HERE! BACK To edit this ORDER !!
    if (this.orderid !== null && this.orderid !== undefined) {
      var order = await this.loadOrder(this.orderid);
      // this.rentalDate.start = order.pickupDate;
      // this.rentalDate.end = order.returnDate;

      var allBranches = await this.loadBranch();
      thisBranch = await allBranches.find(a => a.branchId === order.branchId);
      this.thisBranch = await thisBranch;

      await this.init(thisBranch);
      // thisBranch = await this.comeback(this.orderid);
      // setTimeout(async () => {
      //   await this.comeback(this.orderid);
      // }, 300);

      // console.log("CREATED COMEBACK ::::::::::: this.orderid >>> ", this.orderid);
      await this.comeback(this.orderid);

      // let pickupandreturn =  {start: new Date(order.pickupDate) , end : new Date(order.returnDate)} ;


      this.rentalDate = {}

      this.rentalDate.start = new Date(this.startDate);
      this.rentalDate.end = new Date(this.endDate);


      // console.log("$$$$$$$ Comeback this.start >>> ",this.rentalDate.start);
      // console.log("$$$$$$$ Comeback this.end >>> ",this.rentalDate.end);


    } else {
      // BEGIN >>>>>>> หา branch จาก  role ของ user >>>>>>>>>

       //  *** BEGIN : 2021-01-19
      // thisBranch = await this.findCurrentBranch();
      this.currentBranchId = this.getCurrentBranch.branchId;
      this.thisBranch = this.getCurrentBranch;
      thisBranch = this.getCurrentBranch;
      //  *** BEGIN : 2021-01-19

      // console.log("NEW  :::::::::::  this branch >>> ", thisBranch.branchName);
      await this.init(thisBranch);
      // END >>>>>>> หา branch จาก  role ของ user >>>>>>>>>

      // BEGIN >>>>>>> INITAILZE ITEMS IN BRANCH : params : {thisBranch} >>>>>>>>>
    }
    // this.branchRadio = thisBranch.branchId;
    // this.branchRadio = this.currentBranchId;

    this.proDetail = await this.whatPro();
    // console.log("@@@ RENTAL MOUNTED  this.proDetail >>> ", this.proDetail);
    this.pro = this.proDetail.code;
    // console.log("@@@ RENTAL MOUNTED   this.pro >>> ", this.pro);

    this.customers = await this.loadCustomer();
    // console.log("this.customers >>> ", this.customers);
    // console.log("CREATED  End of functions ::::::::::: this.startDate >>> ", this.startDate);
  },
  async mounted() {
    // >>>>>>> FIND USER ID >>>>>>
      // setTimeout(async () => {
      //   this.delayedDatepicker = true;
      //   this.datecode = this.datecode + 1;
      // }, 200);
     await this.$store.dispatch('shop/fetch');
    //  this.rentalDate = {};
    //  this.rentalDate.start = new Date();
    //  this.rentalDate.end = this.addDay(new Date(), 1);

    //  console.log("this.rentalDate.start  >>> @@@mounted ", this.rentalDate.start );


  },
  methods: {

    //********** BEGIN: 15May2022 */


    async getCustomerInfo(phoneNumber){

      let cust = null;
      try{
        cust = await this.checkCustomerByPhoneNumber(phoneNumber);

      }catch(err){
        // this.$swal.fire({
        //     icon: 'warning',
        //     title: 'ไม่มีข้อมูลลูกค้าในฐานข้อมูล',
        //     // text: 'Something went wrong!',
        //   });
        return;
      }
      this.customerInfo.phoneNumber = phoneNumber;
      this.customerInfo.name = cust.customer.name;
      this.customerInfo.address = cust.customer.address;
      this.customerInfo.birthDate = new Date(this.formatDate(cust.customer.birthDate));
      this.customerInfo.lineId = cust.customer.lineId;
      this.customerInfo.howToKnow = cust.customer.howToKnowShop;
      this.customerInfo.voucher =   cust.maxVoucher;
      this.customerInfo.point =   cust.maxPoint;

    },

    async calculatePromotionDiscount(){


              if(this.usePoint === "" || this.usePoint === undefined){
                this.usePoint = 0;
              }
              if(this.useVoucher === "" || this.useVoucher === undefined){
                this.useVoucher = 0;
              }

              if(this.rentalDiscount === "" || this.rentalDiscount === undefined){
                this.rentalDiscount = 0;
              }
              if(this.bailDiscount === "" || this.bailDiscount === undefined){
                this.bailDiscount = 0;
              }

          let reqOrderDetail = {
                  // "wknOrderId" : "R001",
                  // "orderNumber" : null,
                  branchId: this.getCurrentBranch.branchId,
                  // orderStatus: orderStatus,
                  customerId: "",
                  customerName: this.customerInfo.name,
                  customerAddress: this.customerInfo.address,
                  customerPhone: this.customerPhone,
                  lineId: this.customerInfo.lineId,
                  birthDate: this.$dateFormat(this.customerInfo.birthDate),
                  howToKnowShop: this.customerInfo.howToKnow,
                  accountNumber: "",
                  accountName: "",
                  bankName: "",
                  startDate: this.$dateFormat(this.startDate),
                  endDate: this.$dateFormat(this.endDate),
                  pickupDate: this.$dateFormat(this.startDate),
                  returnDate: this.$dateFormat(this.endDate),
                  receivingMethod:  this.pickupMethod,
                  // paymentMethod: โอนเงิน,
                  remark1: this.remark1,
                  rentalPaymentMethod: this.rentalPaymentMethod,
                  bailPaymentMethod: this.bailPaymentMethod,
                  pointDiscount: this.usePoint,
                  voucherDiscount: this.useVoucher,
                  bailDiscount: this.bailDiscount,
                  rentalDiscount: this.rentalDiscount,
                  useFreeItemPromotion: true,
                  useDiscountPromotion: true
              };


              let reqOrderItems = [];

              //***BEGIN fetch data from cart */

              if (this.displayItems.length > 0) {
                  this.displayItems.forEach(item => {
                    const data = {
                      productItemId: item.productItemId,
                      reservedQuantity: item.reservedQuantity,
                      removed : "n"
                    };
                    reqOrderItems.push(data);

                  });
                }

                if (this.displayItems2.length > 0) {
                  this.displayItems2.forEach(item => {

                        const data = {
                          productItemId: item.productItemId,
                          reservedQuantity: item.reservedQuantity,
                          removed : ""
                        };

                        if (item.removed === "y") {
                          data.removed = "y";
                        }else{
                          data.removed = "n";
                        }

                        reqOrderItems.push(data);

                  });
                }

              // if (this.cart.cartItems.length > 0) {
              //     this.cart.cartItems.forEach(item => {
              //       const data = {
              //         productItemId: item.info.productItemId,
              //         reservedQuantity: item.info.quantity
              //       };
              //       reqOrderItems.push(data);

              //     });
              //   }

              //***END  fetch data from cart */

              // let req = { orderDetail: reqOrderDetail,
              //         orderItems: reqOrderItems
              //       };

              //***  21Jan2022
              try{
                let res = await this.calPromotionDiscount(reqOrderDetail , reqOrderItems);
                this.promotionDiscount = res.promotionDiscount;
                this.promotionDiscountDesc =  res.discountSetting;

              console.log("api.data working",res);


                // console.log("this.promotionDiscount >>> ",this.promotionDiscount, "this.bailDiscount >>> ",this.bailDiscount, "this.rentalDiscount >>> ",this.rentalDiscount);
                // console.log("this.usePoint >>> ",this.usePoint, "this.useVoucher >>> ",this.useVoucher);

              }catch(ex){
                this.promotionDiscount = 0;
                // this.bailDiscount = 0;
                // this.rentalDiscount = 0;
                this.promotionDiscountDesc =  '';
              }





    },

    async prepareSubmitOrder(){

            let req = null;

            let res = null;
            if(this.startDate === null || this.startDate === null){
                this.$swal({
                  icon: 'warning',
                  title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
                });

                return;
              }


              if (this.formatDate(this.startDate) > this.formatDate(this.endDate)) {
                this.noticeWarning(
                  "ข้อมูลไม่ถูกต้อง",
                  "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
                );

                return;
              }

              if (this.customerInfo.point < this.usePoint) {
                this.$swal({
                    icon: 'warning',
                    title: 'Point มีไม่พอ',
                    text: 'Point ที่ใช้ ' + this.usePoint +  ' ลูกค้ามี Point: ' + this.customerInfo.point
                  });



                return;
              }

              if (this.customerInfo.voucher < this.useVoucher) {
                this.$swal({
                    icon: 'warning',
                    title: 'Voucher มีไม่พอ',
                    text: 'Voucher ที่ใช้ ' + this.useVoucher  + ' ลูกค้ามี Voucher:  ' + this.customerInfo.voucher
                  });

                return;
              }

              if (!this.checkCustomerInfo()) {
                return;
              }

              this.$vs.loading();
              this.btnActive = true;

              if(this.usePoint === "" || this.usePoint === undefined){
                this.usePoint = 0;
              }
              if(this.useVoucher === "" || this.useVoucher === undefined){
                this.useVoucher = 0;
              }

              if(this.rentalDiscount === "" || this.rentalDiscount === undefined){
                this.rentalDiscount = 0;
              }
              if(this.bailDiscount === "" || this.bailDiscount === undefined){
                this.bailDiscount = 0;
              }



            let reqOrderDetail = {

                  //  vat : vat,
                  // beforeVat : beforeVat,
                  // netPrice : netPrice,//netPriceDecimal,
                  // bailNetTotal : this.bailTotal - bd,//netPriceDecimal,
                  // inVat : this.taxInfo.vatSystem,
                  // "wknOrderId" : "R001",
                  // "orderNumber" : null,
                  branchId: this.getCurrentBranch.branchId,
                  // orderStatus: orderStatus,
                  customerId: "",
                  customerName: this.customerInfo.name,
                  customerAddress: this.customerInfo.address,
                  customerPhone: this.customerInfo.phoneNumber,
                  lineId: this.customerInfo.lineId,
                  birthDate: this.$dateFormat(this.customerInfo.birthDate),
                  howToKnowShop: this.customerInfo.howToKnow,
                  accountNumber: '',
                  accountName: '',
                  bankName: '',
                  startDate: this.$dateFormat(this.startDate),
                  endDate: this.$dateFormat(this.endDate),
                  receivingMethod:  this.pickupMethod,
                  // paymentMethod: โอนเงิน,
                  remark1: this.remark1,
                  rentalPaymentMethod: this.rentalPaymentMethod,
                  bailPaymentMethod: this.bailPaymentMethod,
                  pointDiscount: this.usePoint,
                  voucherDiscount: this.useVoucher,
                  bailDiscount: this.bailDiscount,
                  rentalDiscount: this.rentalDiscount,
                  useFreeItemPromotion: true,
                  useDiscountPromotion: true
              };

              let reqOrderItems = [];

              //***BEGIN fetch data from cart */

              if (this.displayItems.length > 0) {
                  this.displayItems.forEach(item => {
                    const data = {
                      productItemId: item.productItemId,
                      reservedQuantity: item.reservedQuantity,
                      removed : "n"
                    };
                    reqOrderItems.push(data);

                  });
                }

                if (this.displayItems2.length > 0) {
                  this.displayItems2.forEach(item => {

                        const data = {
                          productItemId: item.productItemId,
                          reservedQuantity: item.reservedQuantity,
                          removed : ""
                        };

                        if (item.removed === "y") {
                          data.removed = "y";
                        }else{
                          data.removed = "n";
                        }

                        reqOrderItems.push(data);

                  });
                }

              // if (this.cart.cartItems.length > 0) {
              //     this.cart.cartItems.forEach(item => {
              //       const data = {
              //         productItemId: item.info.productItemId,
              //         reservedQuantity: item.info.quantity
              //       };
              //       reqOrderItems.push(data);

              //     });
              //   }

              //***END  fetch data from cart */

              req = { orderDetail: reqOrderDetail,
                      orderItems: reqOrderItems,
                      OrderRefId: this.orderid
                    };



              try{
                res = await this.submitEditOrder(req,this.orderid);
              }
              catch(err){
                this.$swal({
                    icon: 'warning',
                    // title: '',
                    text: 'ออเดอร์ถูกแก้ไขหรือยกเลิกเรียบร้อยแล้ว ตรวจสอบที่หน้ารอรับชุด',
                  });
                  this.btnActive = false;
                  this.$vs.loading.close();
                  return;
              }

              if(res == null)
              {
                this.$swal({
                    icon: 'warning',
                    // title: '',
                    text: 'ออเดอร์ถูกแก้ไขหรือยกเลิกเรียบร้อยแล้ว ตรวจสอบที่หน้ารอรับชุด',
                  });
                  this.btnActive = false;
                  this.$vs.loading.close();
                  return;
              }



              if(res.data.success == true)
              {

                this.closePopupOnlineBilling();
                this.$store.commit("cart/resetCart", false);

                  this.$router.push({
                  path: "/backoffice/order-detail/" + res.data.orderHeaderId
                });
                this.btnActive = false;
                this.$vs.loading.close();
              }

              // console.log("req >>> ",req , " res >>> ",res);

              // console.log("reqOrderDetail >>> ",reqOrderDetail);

              // console.log("reqOrderItems >>> ",reqOrderItems);

              this.btnActive = false;
              this.$vs.loading.close();





    },


     modalExpandToggle() {
      if (this.modalExpand == "none") {
        this.modalExpand = "block";
      } else {
        this.modalExpand = "none";
      }
    },

    closePopupOnlineBilling() {
      this.popupOnlineBillingActive = false;
      this.$store.commit("cart/checkRentalListInput", false);
      this.confirmSubmit = !this.confirmSubmit;
      this.customerInfo= {
        phoneNumber: '',
        name: '',
        address: '',
        lineId: '',
        birthDate: '',
        howToKnow: '',
        point: 0,
        voucher: 0,
        bailDiscount: '',
        bailDiscountPercent: '',
        rentalDiscount: '',
        rentalDiscountPercent: '',
        promotionDiscount: '',
        pickupMethod: '',
        paymentType: 'เงินสด',
        paymentInsuranceType: '',
        note: ''
      }
    },

    //********** END: 15May2022 */

    undoFlag(ind) {
      var item = this.displayItems2[ind];
      item.removed = "n";

      this.displayItems2[ind].removed = "n";
      this.$set(this.displayItems2, ind, item);
      // console.log("@@@@@ REMOVE FLAG >>> ", this.displayItems2);
    },
    removeFlag(ind) {
      var item = this.displayItems2[ind];
      item.removed = "y";

      this.displayItems2[ind].removed = "y";
      this.$set(this.displayItems2, ind, item);
      // console.log("@@@@@ REMOVE FLAG >>> ", this.displayItems2);
    },
    toDate() {
      var dd = new Date();
      return this.formatDate(dd);
    },


    descendingSort(inputList) {
      var list = [];
      for (var i = 0; i < inputList.length; i++) {
        list.push(inputList[i]);
      }
      list.sort((a, b) => b.rentalPrice - a.rentalPrice);
      return list;
    },

    ascendingSort(inputList) {
      var list = [];
      for (var i = 0; i < inputList.length; i++) {
        list.push(inputList[i]);
      }
      list.sort((a, b) => a.rentalPrice - b.rentalPrice);
      return list;
    },

    allQty(inputList) {
      var num = 0;
      for (var i = 0; i < inputList.length; i++) {
        num = num + inputList[i].reservedQuantity;
      }
      return num;
    },
    proDiscount(countItem, inputList) {
      var amount = 0;
      var ind = 0;
      var acc = 0;
      while (acc < countItem) {
        var qtyInLine = inputList[ind].reservedQuantity;

        if (qtyInLine + acc <= countItem) {
          amount = amount + inputList[ind].rentalPrice * qtyInLine;
          acc = acc + qtyInLine;
          // console.log("IN IF คิดหมด >>>> ", qtyInLine);
        } else {
          amount = amount + inputList[ind].rentalPrice * (countItem - acc);
          // console.log("IN ELSE คิดส่วนเดียว >>>>> ", countItem - acc);
          acc = acc + qtyInLine;
        }
        ind = ind + 1;

        // console.log(
        //   " [",
        //   ind,
        //   "] :  qtyInLine  >>>  ",
        //   qtyInLine,
        //   "  acc >>> ",
        //   acc,
        //   "  amount >>> ",
        //   amount
        // );
      }
      return amount;
    },

    buy1get1() {
      var list = [];
      list = list.concat(this.displayItems);

      var tmplist2 = this.displayItems2.filter(r => r.removed === "n");
      list = list.concat(tmplist2);

      list = this.ascendingSort(list);
      var ll = list.filter(
        ss =>
          ss.category !== "F" &&
          ss.category !== "L" &&
          ss.category !== "S" &&
          ss.rentalPrice > 0
      );

      var allqty = this.allQty(ll);
      // console.log("allqty: ", allqty, "  buy1get1 list >>> ", ll);

      var countItem = Math.floor(allqty / 2);

      // ใช้ list ที่เรียงจากน้อยไปมาก + จำนวน Item ที่ไม่คิดเงิน
      var discount = this.proDiscount(countItem, ll);
      // console.log("@@@@RENTALPRICE >>> BUY1GET1 >>> ", countItem);

      return discount;
    },

    buy2get1() {
      var list = [];
      list = list.concat(this.displayItems);

      var tmplist2 = this.displayItems2.filter(r => r.removed === "n");
      list = list.concat(tmplist2);

      list = this.ascendingSort(list);
      var ll = list.filter(
        ss =>
          ss.category !== "F" &&
          ss.category !== "L" &&
          ss.category !== "S" &&
          ss.rentalPrice > 0
      );

      var allqty = this.allQty(ll);
      // console.log("allqty: ", allqty, "  buy1get1 list >>> ", ll);
      var countItem = Math.floor(allqty / 3);

      // ใช้ list ที่เรียงจากน้อยไปมาก + จำนวน Item ที่ไม่คิดเงิน
      var discount = this.proDiscount(countItem, ll);
      // console.log("@@@@RENTALPRICE >>> BUY2GET1 >>> ", discount);

      return discount;
    },

    grandtotalDiscount() {
      var discount = 0;
      var list = [];
      list = list.concat(this.displayItems);

      var tmplist2 = this.displayItems2.filter(r => r.removed === "n");
      list = list.concat(tmplist2);

      list = this.ascendingSort(list);
      var ll = list.filter(
        ss =>
          ss.category !== "F" &&
          ss.category !== "L" &&
          ss.category !== "S" &&
          ss.rentalPrice > 0
      );

      var renp = 0;

      for (var i = 0; i < ll.length; i++) {
        renp = renp + ll[i].rentalPrice;
      }

      if (renp >= this.proDetail.startAmount) {
        discount = (renp * this.proDetail.discount) / 100;
      }
      return discount;
    },
    calculatePromotion() {
      if (this.pro === "1") {
        this.promotionDiscount = this.buy1get1();
      } else if (this.pro === "2") {
        this.promotionDiscount = this.buy2get1();
      } else if (this.pro === "3") {
        this.promotionDiscount = this.grandtotalDiscount();
      } else {
        this.promotionDiscount = 0;
      }
    },
    async whatPro() {
      var allpro = await this.loadPro();
      var proobj = {};
      for (var i = 0; i < allpro.length; i++) {
        if (allpro[i].state === true) {
          proobj = await allpro[i];
        }
      }
      return proobj;
    },

    async loadPro() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/promotion",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        return null;
      }

      return res.data;
    },

    async loadCustomer() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/customer",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        this.noticeError(
          "โหลดข้อมูลลูกค้าไม่สำเร็จ โปรด refresh page อีกครั้ง"
        );
        return null;
      }

      return res.data;
    },

    thisordno(ord) {
      if (ord === null || ord === undefined || ord === "")
        return "ยังไม่มีเลข Order";
      else return ord;
    },
    // date_diff_indays(date1, date2) {
    //   var dt1 = new Date(date1);
    //   var dt2 = new Date(date2);
    //   return Math.floor(
    //     (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
    //       Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    //       (1000 * 60 * 60 * 24)
    //   );
    // },

    // addDay(date, days) {
    //   const copy = new Date(Number(date));
    //   copy.setDate(date.getDate() + days);
    //   return copy;
    // },

    async checkQueueAll() {
      // console.log('******* async checkQueueAll >>> ',this.startDate );
      // console.log('******* async checkQueueAll >>> ',this.endDate );

      if (
        this.startDate === null ||
        this.startDate === undefined ||
        this.endDate === null ||
        this.endDate === undefined
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "ยังไม่ได้กำหนดวันรับชุด หรือวันคืนชุด"
        );

        return;
      }

      if (Object.entries(this.rentalDate).length === 0 ) {
          this.$swal({
            icon: 'warning',
            title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
          });

          return;
      }


      if (this.formatDate(this.startDate) > this.formatDate(this.endDate)) {
        this.noticeWarning(
          "ข้อมูลไม่ถูกต้อง",
          "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
        );

        return;
      }

      this.$vs.loading();

      // *** BEGIN 2021-01-19
      this.activeBtn = true;
      // *** END 2021-01-19


      await this.checkQueue();
      await this.checkQueue2();
      this.checkRentalList =
        (await this.checkRentalList1) && this.checkRentalList2;
      if (this.checkRentalList === false)
        this.noticeWarning("ตรวจสอบอีกครั้ง", "มีชุดที่คิวไม่ว่าง");


      this.calculatePromotionDiscount();

      this.$vs.loading.close();
    },

    // >>>>>>> CHECK QUEUE >>>>>>>>>
    async checkQueue() {
      // เช็ค productItemId / this.startDate >=  /  =< this.endDate
      //  ต้องเทียบเป็นวัน
      // เอาจำนวนที่ยืม + จำนวนที่ยืมไปแล้ว <= จำนวนที่มีใน Branch

      var temp = this.displayItems;
      for (var av = 0; av < this.displayItems.length; av++) {
        this.avanew[av] = false;
      }
      // if (
      //   this.displayItems === null ||
      //   this.displayItems === undefined ||
      //   this.displayItems.length === 0
      // ) {
      //   return;
      // }
      var diffDate = this.date_diff_indays(this.startDate, this.endDate);
      diffDate = diffDate + this.washingDate;

      var dlist = await this.loadAllDetail();
      // console.log("diffDate >>> ", diffDate);
      // console.log("temp.length >>> ", temp.length);

      for (var i = 0; i < temp.length; i++) {
        // Challenge by set notAvailable to FALSE
        temp[i].notAvailable = false;

        var itemId = temp[i].productItemId;
        console.log("temp[", i, "]  >> ", temp[i].code);

        // item ที่ได้คือ item ใน branch stock
        var item = this.itemsInBranch.find(x => x.productItemId === itemId);
        if (item === null || item === undefined) {
          temp[i].notAvailable = true;
        } else {
          // ถ้าของมีจำนวน 0
          if (item.amountInBranch === 0) {
            temp[i].notAvailable = true;
          }

          else if (item.amountInBranch === undefined || item.amountInBranch === null) {
            temp[i].notAvailable = true;
            item.amountInBranch = 0;
          }
        }

        console.log(
            "  item.amountInBranch >>>  ",
            item.amountInBranch
          );

        // item ที่ได้คือ item ใน branch stock
        // เอาค่า item.amountInBranch
        // ต้องเทียบทีละวัน

        for (var da = 0; da < diffDate + 1; da++) {
          // เริ่มเทียบวันแรก ค่อยๆ เทียบไปทีละวันจนวันสุดท้าย
          // var theDate = this.addDay(new Date(this.startDate), da);
          var theDate = this.addDay(new Date(this.startDate), da);
          theDate = this.formatDate(theDate);
          // console.log("theDate >>> ", theDate);

          // dlist คือ orderDetail List ทั้งหมด
          // state = true คือ queue ที่โดน LOCK อยู่ !!!
          var condition = await dlist.filter(
            f =>
              f.productItemId === temp[i].productItemId &&
              f.orderHeaderId !== this.orderid &&
              this.formatDate(f.startDate) <= theDate &&
              theDate <= this.formatDate(f.wnfReceivingDate) &&
              f.state === true &&
              // เอาแต่เฉพาะสินค้าของ Branch นี้
              f.branchId === this.currentBranchId
          );

          // console.log("temp[", i, "][", da, "] condition >>> ", condition);

          var conditionSum = 0;
          for (var j = 0; j < condition.length; j++) {
            conditionSum = conditionSum + (await condition[j].reservedQuantity);
          }

          // **** >>>>>> หาจำนวนใน list เก่าด้วย
          var thisProductId = temp[i].productItemId;
          var thisQty = temp[i].reservedQuantity;

          // **** >>>>>> Product Id เท่ากับตัวใหม่ และ ติด flag1 หรือ removed เป็น 'n' (ตัวที่ลบแล้วไม่เอามาคิด)
          var filtemp = this.displayItems2.filter(
            x => x.productItemId === thisProductId && x.removed === "n"
          );

          for (var v = 0; v < filtemp.length; v++) {
            thisQty = thisQty + filtemp[v].reservedQuantity;
          }

          // console.log(
          //   "CHECKQ1 :::: thisProductId >>> ",
          //   thisProductId,
          //   " [",
          //   i,
          //   "] : thisQty >>> ",
          //   thisQty
          // );

          // **** >>>>>> หาจำนวนใน list ใหม่ด้วย
          // ค่า reservedQuantity ทั้งหมด + ตัวมันเอง ต้องไม่มากกว่า ของที่มีใน stock
          var allReservedQuantity = thisQty + (await conditionSum);

          console.log(
            "allReservedQuantity >>> ",
            allReservedQuantity,
            "  item.amountInBranch >>>  ",
            item.amountInBranch
          );

          if ((await allReservedQuantity) > item.amountInBranch) {
            temp[i].notAvailable = true;
            this.$set(this.avanew, i, true);
            // console.log("ไม่ว่างงงงงงงงงงงงงงงงงงงงงงงงงงงงงงงงงงงงง");

            // ถ้าไม่ว่างก็ดู item ต่อไปได้เลย
            break;
          }
        }
      }

      // ดูว่า รายการเช่าชุดไปต่อได้มั้ย
      var countValid = [];
      countValid = temp.filter(c => c.notAvailable === true);

      if (
        countValid.length > 0 &&
        countValid !== undefined &&
        countValid !== null
      ) {
        this.checkRentalList1 = false;
        //  this.noticeWarning("ตรวจสอบอีกครั้ง", "มีชุดที่คิวไม่ว่าง");
      } else {
        this.checkRentalList1 = true;
      }

      // update displayItems
      this.displayItems = [];

      for (var x = 0; x < temp.length; x++) {
        // console.log("FINAL CHECK  >>>> [", x, "] : ", temp[x].notAvailable);
        this.$set(this.displayItems, x, temp[x]);

        // this.displayItems.push(temp[x]);
      }

      // console.log("CHECK QUEUE this.avanew >>>> ", this.avanew);
    },
    // >>>>>>> CHECK QUEUE >>>>>>>>>
    // >>>>>>> CHECK QUEUE 2 >>>>>>>>>
    async checkQueue2() {
      // เช็ค productItemId / this.startDate >=  /  =< this.endDate
      //  ต้องเทียบเป็นวัน
      // เอาจำนวนที่ยืม + จำนวนที่ยืมไปแล้ว <= จำนวนที่มีใน Branch

      var temp = this.displayItems2;
      // if (
      //   this.displayItems2 === null ||
      //   this.displayItems2 === undefined ||
      //   this.displayItems2.length === 0
      // ) {
      //   return;
      // }
      var diffDate = this.date_diff_indays(this.startDate, this.endDate);
      diffDate = diffDate + this.washingDate;

      var dlist = await this.loadAllDetail();
      // console.log("dlist >>> ", dlist);

      for (var i = 0; i < temp.length; i++) {
        // ดักว่าถ้ามีการลบไปแล้ว ไม่ต้องนำมาเช็คคิว
        if (temp[i].removed === "y") {
          temp[i].notAvailable = false;
          continue;
        }


        // Challenge by set notAvailable to FALSE
        temp[i].notAvailable = false;

        var itemId = temp[i].productItemId;

        // console.log("this.itemsInBranch  >> ", this.itemsInBranch);
        // console.log("temp[", i, "]  >> ", temp[i].productItemId);
        // console.log("itemId  >> ", itemId);

        // item ที่ได้คือ item ใน branch stock
        var itmInBran = this.itemsInBranch.find(x => x.productItemId === itemId);

        console.log("itmInBran >>> ",itmInBran)

         var item = itmInBran;

        // console.log("item  >> ", item);

        if (item === null || item === undefined) {
          temp[i].notAvailable = true;
        } else {
          // ถ้าของมีจำนวน 0
          if (item.amountInBranch === 0) {
            temp[i].notAvailable = true;
          }
        }

        // item ที่ได้คือ item ใน branch stock
        // เอาค่า item.amountInBranch
        // ต้องเทียบทีละวัน

        for (var da = 0; da < diffDate + 1; da++) {
          // เริ่มเทียบวันแรก ค่อยๆ เทียบไปทีละวันจนวันสุดท้าย
          // var theDate = this.addDay(new Date(this.startDate), da);
          var theDate = this.addDay(new Date(this.startDate), da);
          theDate = this.formatDate(theDate);
          // console.log("theDate >>> ", theDate);

          // dlist คือ orderDetail List ทั้งหมด
          // state = true คือ queue ที่โดน LOCK อยู่ !!!
          var condition = await dlist.filter(
            f =>
              f.productItemId === temp[i].productItemId &&
              f.orderHeaderId !== this.orderid &&
              this.formatDate(f.startDate) <= theDate &&
              theDate <= this.formatDate(f.wnfReceivingDate) &&
              f.state === true &&
              // เอาแต่เฉพาะสินค้าของ Branch นี้
              f.branchId === this.currentBranchId
          );

          // console.log("temp[", i, "][", da, "] condition >>> ", condition);

          var conditionSum = 0;
          for (var j = 0; j < condition.length; j++) {
            conditionSum = conditionSum + condition[j].reservedQuantity;
          }

          // ค่า reservedQuantity ทั้งหมด + ตัวมันเอง ต้องไม่มากกว่า ของที่มีใน stock

          // **** >>>>>> หาจำนวนใน list ที่ขึ้นบนหน้าจอทั้งหมด

          // **** >>>>>> หาจำนวนใน list ใหม่ด้วย
          // var thisProductId = temp[i].productItemId;

          // var filtemp = this.displayItems1.filter(
          //   x => x.productItemId === thisProductId
          // );
          // var thisQty = 0;
          // for (var v = 0; v < filtemp.length; v++) {
          //   thisQty = thisQty + filtemp[v].reservedQuantity;
          // }

        // **** >>>>>> หาจำนวนใน list ใหม่ด้วย
        // **** >>>>>> 06FEB2022

        var sameCode2 = this.displayItems2.filter(x => x.code === temp[i].code && x.removed !== "y");

        var qty2 = 0;
        sameCode2.forEach(function(d)
        {
          qty2 = qty2 + d.reservedQuantity;
        });

        // var qty2 =  sameCode2.reduce(function() (accum , item) { return  accum + item.reservedQuantity }, 0 );

        var sameCode1 = this.displayItems.filter(x => x.code === temp[i].code && x.removed !== "y");

        // var qty1 =  sameCode1.reduce(function() (accum , item) { return  accum + item.reservedQuantity }, 0 );

         var qty1 = 0;
        sameCode1.forEach(function(d)
        {
          qty1 = qty1 + d.reservedQuantity;
        });

        var allQty = qty1 + qty2;

        // **** >>>>>> 06FEB2022

        // console.log("item >>> ",item);
        if(item === null || item===undefined){
          item = {}
        }
        if(item["amountInBranch"] === null || item["amountInBranch"]===undefined){
          item["amountInBranch"] = 0;
        }

          var allReservedQuantity = allQty + conditionSum;

          if (allReservedQuantity > item.amountInBranch) {
            temp[i].notAvailable = true;
            // ถ้าไม่ว่างก็ดู item ต่อไปได้เลย
            break;
          }
        }
      }

      // ดูว่า รายการเช่าชุดไปต่อได้มั้ย
      var countValid = [];
      countValid = temp.filter(c => c.notAvailable === true);

      if (
        countValid.length > 0 &&
        countValid !== undefined &&
        countValid !== null
      ) {
        this.checkRentalList2 = false;
        // this.noticeWarning("ตรวจสอบอีกครั้ง", "มีชุดที่คิวไม่ว่าง");
      } else {
        this.checkRentalList2 = true;
      }

      // update displayItems2
      this.displayItems2 = [];

      for (var x = 0; x < temp.length; x++) {
        this.displayItems2.push(temp[x]);
      }

      // console.log("CHECK QUEUE >>>> ", this.displayItems2);
    },
    checkCustomerInfo() {
      // >>>>>>>>>> Validate INFO
      if (
        this.customerInfo.name === null ||
        this.customerInfo.name === undefined ||
        this.customerInfo.name === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อลูกค้า");
        return false;
      }
      if (
        this.customerInfo.address === null ||
        this.customerInfo.address === undefined ||
        this.customerInfo.address === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ที่อยู่ลูกค้า");
        return false;
      }
      if (
        this.customerInfo.phoneNumber === null ||
        this.customerInfo.phoneNumber === undefined ||
        this.customerInfo.phoneNumber === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่เบอร์โทรลูกค้า");
        return false;
      }

      return true;

      // >>>>>>>>>> Validate INFO
    },
    // >>>>>>> CHECK QUEUE 2 >>>>>>>>>
    // checkCustomerInfo() {
    //   // >>>>>>>>>> Validate INFO
    //   if (
    //     this.customerName === null ||
    //     this.customerName === undefined ||
    //     this.customerName === ""
    //   ) {
    //     this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อลูกค้า");
    //     return false;
    //   }
    //   if (
    //     this.customerAddress === null ||
    //     this.customerAddress === undefined ||
    //     this.customerAddress === ""
    //   ) {
    //     this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ที่อยู่ลูกค้า");
    //     return false;
    //   }
    //   if (
    //     this.customerPhone === null ||
    //     this.customerPhone === undefined ||
    //     this.customerPhone === ""
    //   ) {
    //     this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่เบอร์โทรลูกค้า");
    //     return false;
    //   }

    //   return true;

    //   // >>>>>>>>>> Validate INFO
    // },
    async hold() {
      // >>>>>>>>>> Validate INFO
      if (this.formatDate(this.startDate) > this.formatDate(this.endDate)) {
        this.noticeWarning(
          "ข้อมูลไม่ถูกต้อง",
          "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
        );

        return;
      }
      if (!this.checkCustomerInfo()) {
        return;
      }
      // >>>>>>>>>> Validate INFO
      this.$vs.loading();
      // SAVE HEADER

      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);



      var theGrandTotal = 0;
      if(this.taxInfo.vatSystem === true)
      {
        theGrandTotal = this.rentalPriceTotal_final(this.rentalPriceTotal, rd) + this.bailTotal - (bd + pd);
      }else{
        theGrandTotal = Number(this.grandTotal) - (bd + rd + pd)
      }

      // *** END : 2021-04-13 NET Grand Total

      // *** BEGIN : 2021-04-14 ADD VAT

      var vat = 0;
      var beforeVat = 0;
      var netPrice = 0;

      if(this.taxInfo.vatSystem === true)
      {
        vat = this.vat_final(this.rentalPriceTotal, rd);
        beforeVat = this.beforeVat_final(this.rentalPriceTotal, rd);
      netPrice = this.rentalPriceTotal_final(this.rentalPriceTotal, rd) - pd;
      }else{

        // netPrice = this.rentalPriceTotal_final(this.rentalPriceTotal, rd) - pd;
         netPrice = this.rentalPriceTotal - (rd + pd);
      }




      var ord = {

        vat : vat,
        beforeVat : beforeVat,
        netPrice : netPrice,
        bailNetTotal : this.bailTotal - bd,//netPriceDecimal,
        inVat : this.taxInfo.vatSystem,

      // *** END : 2021-04-14 ADD VAT
        // orderHeaderId: "string",
        orderRef: this.orderid,
        orderNumber: this.orderNumber,
        // orderNumberRef: oldOrderNumber,
        branchId: this.currentBranchId,
        orderStatus: "เก็บสิทธิ์",
        remark1: this.remark1,
        // remark2: "string",
        // remark3: "string",
        // remark4: "string",
        customerId: "string",
        customerName: this.customerInfo.name,
        customerAddress: this.customerInfo.address,
        customerPhone: this.customerInfo.phoneNumber,

        receivingMethod: this.receivingMethod,
        pickupDate: this.startDate,
        returnDate: this.endDate,
        rentalPriceTotal: this.rentalPriceTotal,
        bailTotal: this.bailTotal,
        grandTotal: theGrandTotal,

        rentalDiscount: this.rentalDiscount,
        bailDiscount: this.bailDiscount,
        promotionDiscount: this.promotionDiscount,
        rentalPaymentMethod: this.order.rentalPaymentMethod,
        rentalPaymentDate: this.order.rentalPaymentDate,
        bailPaymentMethod: this.order.bailPaymentMethod,
        bailPaymentDate: this.order.bailPaymentDate,
        description: "",
        state: true,
        // createDateTime: "",
        createBy: this.currentUser,
        // updateDateTime: "",
        updateBy: this.currentUser
      };

      // * >>>> ยกเลิกบิลเดิมก่อน
      var cancelres = null;
      try {
        cancelres = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/cancelorder/" +
            this.orderid,

          { updateBy: this.currentUser },
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        cancelres = null;
      }

      if (cancelres === null) {
        this.noticeError("ยกเลิก ", this.orderid, "ไม่สำเร็จ");
        return;
      }
      // * >>>> ยกเลิกบิลเดิมก่อน

      // >>>> START TO SAVE HEADER
      var responseOrder = null;
      try {
        responseOrder = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader/revise",
          ord,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        responseOrder = null;
        return;
      }

      if (responseOrder !== null) {
        this.orderHeader = await responseOrder.data;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("บันทึกสำเร็จ");
        }, 100);
      } else {
        responseOrder = null;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
          return;
        }, 100);
      }

      // SAVE DETAIL
      var dlist = [];


      for (var d2 = 0; d2 < this.displayItems2.length; d2++) {
        var detail2 = {};

        var flag = "k";

        if(this.displayItems2[d2].removed === "y" ){
          flag = this.displayItems2[d2].removed;
        }

        if(this.displayItems2[d2].removed === "n"){
          flag = "k";
        }


        detail2 = {
          orderHeaderId: await this.orderHeader.orderHeaderId,
          branchId: this.currentBranchId,
          productItemId: this.displayItems2[d2].productItemId,
          code: this.displayItems2[d2].code,
          // *** update 2021-01-26
          name: this.displayItems2[d2].name,
          mainImage: this.displayItems2[d2].mainImage,
          // *** update 2021-01-26
          reservedQuantity: this.displayItems2[d2].reservedQuantity,
          rentalPrice: this.displayItems2[d2].rentalPrice,
          bail: this.displayItems2[d2].bail,
          state: false,
          startDate: this.startDate,
          endDate: this.endDate,
          createBy: this.currentUser,
          updateBy: this.currentUser,
          flag1: flag
        };
        // }

        dlist.push(detail2);
      }

      // >>>> START TO SAVE HEADER

      var responseDetail = null;
      try {
        responseDetail = await this.$http.post(
          this.$store.state.apiURL + "/api/orderdetail/keepright",
          dlist,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        responseDetail = null;
      }

      if (responseDetail !== null) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.$router.push({
            path: "/backoffice/order-detail/" + this.orderHeader.orderHeaderId
          });
        }, 200);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 100);
        this.noticeError("บันทึกไม่สำเร็จ");
      }

      // >>>> START TO SAVE HEADER
    },

    async create() {
      // >>>>>>>>>> Validate INFO
      if (this.formatDate(this.startDate) > this.formatDate(this.endDate)) {
        this.noticeWarning(
          "ข้อมูลไม่ถูกต้อง",
          "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
        );

        return;
      }

      if(this.rentalDate === null){
        this.$swal({
          icon: 'warning',
          title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
        });

        return;
      }

      if (!this.checkCustomerInfo()) {
        return;
      }
      // >>>>>>>>>> Validate INFO
      this.$vs.loading();
      this.btnActive = true;
      // SAVE HEADER

      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      // console.log(
      //   "@@@@ CREATE >>> BRANCH___________ID >>> ",
      //   this.currentBranchId
      // );

      // *** BEGIN : 2021-04-13 NET Grand Total
      // var netTotal = 0;
      // var netTotal = this.rentalPriceTotal_final(this.rentalPrice, rd);

      var theGrandTotal = 0;
      if(this.taxInfo.vatSystem === true)
      {
        theGrandTotal = this.rentalPriceTotal_final(this.rentalPriceTotal, rd) + this.bailTotal - (bd + pd);
      }else{
        theGrandTotal = Number(this.grandTotal) - (bd + rd + pd)

      }


      // *** END : 2021-04-13 NET Grand Total

      // *** BEGIN : 2021-04-14 ADD VAT

      var vat = 0;
      var beforeVat = 0;
      var netPrice = 0;

      // if(this.taxInfo.vatSystem === true)
      // {
      //   vat = this.vat_final(this.rentalPriceTotal, rd);
      //   beforeVat = this.beforeVat_final(this.rentalPriceTotal, rd);
      //   netPrice = this.rentalPriceTotal_final(this.rentalPriceTotal, rd);
      // }else{

      //   netPrice = this.rentalPriceTotal;
      // }

       if(this.taxInfo.vatSystem === true)
      {
        vat = this.vat_final(this.rentalPriceTotal, rd);
        beforeVat = this.beforeVat_final(this.rentalPriceTotal, rd);
        netPrice = this.rentalPriceTotal_final(this.rentalPriceTotal, rd) - pd;
        // console.log("NETPRICE >>> VATSYSTEM");
      }else{

        // netPrice = this.rentalPriceTotal_final(this.rentalPriceTotal, rd) - pd;
        netPrice = this.rentalPriceTotal - (rd + pd);
        // console.log("this.rentalPriceTotal  >>> NO VATSYSTEM", this.rentalPriceTotal);
        // console.log("rd  >>> NO VATSYSTEM", rd);
        // console.log("pd  >>> NO VATSYSTEM", pd);
        // console.log("this.rentalPriceTotal_final(this.rentalPriceTotal, rd) >>> NO VATSYSTEM", this.rentalPriceTotal_final(this.rentalPriceTotal, rd));
      }

      // console.log("NETPRICE >>> ",netPrice);



      // var netPriceDecimal = isNaN(Number(netPrice)) ? 0 : Number(netPrice);



      var ord = {
        vat : vat,
        beforeVat : beforeVat,
        netPrice : netPrice,//netPriceDecimal,
        bailNetTotal : this.bailTotal - bd,//netPriceDecimal,
        inVat : this.taxInfo.vatSystem,

      // *** END : 2021-04-14 ADD VAT

        // orderHeaderId: "string",
        orderRef: this.orderid,
        orderNumber: this.orderNumber,
        // orderNumberRef: oldOrderNumber,
        branchId: this.currentBranchId,
        orderStatus: "รอรับชุด",
        remark1: this.remark1,
        // remark2: "string",
        // remark3: "string",
        // remark4: "string",
        customerId: "string",
        customerName: this.customerName,
        customerAddress: this.customerAddress,
        customerPhone: this.customerPhone,
        // accountNumber: "string",
        // accountName: "string",
        // bankName: "string",
        // transferContact: "string",
        receivingMethod: this.receivingMethod,
        pickupDate: this.startDate,
        returnDate: this.endDate,
        rentalPriceTotal: this.rentalPriceTotal,
        bailTotal: this.bailTotal,
        grandTotal: theGrandTotal,

        rentalDiscount: this.rentalDiscount,
        bailDiscount: this.bailDiscount,
        promotionDiscount: this.promotionDiscount,
        // deduction: 0,
        // deductionDate: "2019-07-14T09:40:20.607Z",
        // bailReturningAmount: 0,
        // bailReturningStatus: "string",
        // bailReturningDate: "2019-07-14T09:40:20.607Z",
        description: "",
        rentalPaymentMethod: this.order.rentalPaymentMethod,
        rentalPaymentDate: this.order.rentalPaymentDate,
        bailPaymentMethod: this.order.bailPaymentMethod,
        bailPaymentDate: this.order.bailPaymentDate,
        state: true,
        // createDateTime: "",
        createBy: this.currentUser,
        // updateDateTime: "",
        updateBy: this.currentUser
      };

      // console.log(this.order);


      // >>>> START TO SAVE HEADER
      var responseOrder = null;
      try {
        responseOrder = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader/revise",
          ord,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        responseOrder = null;
        this.btnActive = false;
        this.$vs.loading.close();
        return;
      }

      if (responseOrder !== null) {
        this.orderHeader = await responseOrder.data;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("บันทึกสำเร็จ");
        }, 100);
      } else {
        responseOrder = null;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
          return;
        }, 100);
      }

      // SAVE DETAIL
      var dlist = [];
      for (var d = 0; d < this.displayItems.length; d++) {
        // console.log(" dis 1 : ", this.displayItems[d].code , " : ", this.displayItems[d].reservedQuantity , " remove ", this.displayItems[d].removed);

        var detail = {
          orderHeaderId: await this.orderHeader.orderHeaderId,
          branchId: this.currentBranchId,
          productItemId: this.displayItems[d].productItemId,
          code: this.displayItems[d].code,
          // *** update 2021-01-26
          name: this.displayItems[d].name,
          mainImage: this.displayItems[d].mainImage,
          // *** update 2021-01-26
          reservedQuantity: this.displayItems[d].reservedQuantity,
          rentalPrice: this.displayItems[d].rentalPrice,
          bail: this.displayItems[d].bail,
          state: true,
          startDate: this.startDate,
          endDate: this.endDate,
          createBy: this.currentUser,
          updateBy: this.currentUser,
          flag1: "n"
        };

        dlist.push(detail);
      }

      for (var d2 = 0; d2 < this.displayItems2.length; d2++) {
        // console.log(" dis 2 : ", this.displayItems2[d2].code , " : ", this.displayItems2[d2].reservedQuantity , " remove ", this.displayItems2[d2].removed);
        var detail2 = {};
        if (this.displayItems2[d2].removed === "y") {
          detail2 = {
            orderHeaderId: await this.orderHeader.orderHeaderId,
            branchId: this.currentBranchId,
            productItemId: this.displayItems2[d2].productItemId,
            code: this.displayItems2[d2].code,
            reservedQuantity: this.displayItems2[d2].reservedQuantity,
            rentalPrice: this.displayItems2[d2].rentalPrice,
            bail: this.displayItems2[d2].bail,
            state: true,
            startDate: this.startDate,
            endDate: this.endDate,
            createBy: this.currentUser,
            updateBy: this.currentUser,
            flag1: "y"
          };
        } else {
          detail2 = {
            orderHeaderId: await this.orderHeader.orderHeaderId,
            branchId: this.currentBranchId,
            productItemId: this.displayItems2[d2].productItemId,
            code: this.displayItems2[d2].code,
            reservedQuantity: this.displayItems2[d2].reservedQuantity,
            rentalPrice: this.displayItems2[d2].rentalPrice,
            bail: this.displayItems2[d2].bail,
            state: false,
            startDate: this.startDate,
            endDate: this.endDate,
            createBy: this.currentUser,
            updateBy: this.currentUser,
            flag1: "n"
          };
        }

        dlist.push(detail2);
      }

      // console.log('dlist >>>> ',dlist);


      // * >>>> ยกเลิกบิลเดิมก่อน
      var cancelres = null;
      try {
        cancelres = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/cancelorder/" +
            this.orderid,

          { updateBy: this.currentUser },
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        cancelres = null;
        return;
      }

      if (cancelres === null) {
        this.noticeError("ยกเลิก ", this.orderid, "ไม่สำเร็จ");
        return;
      }

      // * >>>> ยกเลิกบิลเดิมก่อน

      // >>>> START TO SAVE HEADER

      var responseDetail = null;
      try {
        responseDetail = await this.$http.post(
          this.$store.state.apiURL + "/api/orderdetail",
          dlist,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        responseDetail = null;
      }

      if (responseDetail !== null) {
        this.$vs.loading.close();
        setTimeout(() => {
          this.$vs.loading.close();
          this.$router.push({
            path: "/backoffice/order-detail/" + this.orderHeader.orderHeaderId
          });
        }, 500);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 500);
        this.noticeError("บันทึกไม่สำเร็จ");
      }

      this.$vs.loading.close();

      // >>>> START TO SAVE HEADER
    },



    // async update() {
    //   // >>>>>>>>>> Validate INFO
    //   if(this.rentalDate === null){
    //     this.$swal({
    //       icon: 'warning',
    //       title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
    //     });

    //     return;
    //   }

    //   if (!this.checkCustomerInfo()) {
    //     // console.log("********NO CUSTOMER INFO !!!");

    //     return;
    //   }

    //   // >>>>>>>>>> Validate INFO
    //   this.$vs.loading({ type: "radius" });

    //   // SAVE HEADER

    //   var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);
    //   var rd = isNaN(Number(this.rentalDiscount))
    //     ? 0
    //     : Number(this.rentalDiscount);

    //   var pd = isNaN(Number(this.promotionDiscount))
    //     ? 0
    //     : Number(this.promotionDiscount);

    //   console.log(
    //     "@@@@ RENTAL >>> WHEN UPDATE  PROMOTION >>> ",
    //     this.promotionDiscount
    //   );

    //   var ord = {
    //     // orderHeaderId: "string",
    //     orderRef: this.orderHeaderId,
    //     orderNumber: this.orderNumber,
    //     // orderNumberRef: "string",
    //     branchId: this.currentBranchId,
    //     orderStatus: "บันทึก",
    //     remark1: this.remark1,
    //     // remark2: "string",
    //     // remark3: "string",
    //     // remark4: "string",
    //     customerId: "string",
    //     customerName: this.customerName,
    //     customerAddress: this.customerAddress,
    //     customerPhone: this.customerPhone,
    //     // accountNumber: "string",
    //     // accountName: "string",
    //     // bankName: "string",
    //     // transferContact: "string",
    //     receivingMethod: this.receivingMethod,
    //     pickupDate: this.startDate,
    //     returnDate: this.endDate,
    //     rentalPriceTotal: this.rentalPriceTotal,
    //     bailTotal: this.bailTotal,
    //     grandTotal: Number(this.grandTotal) - (bd + rd + pd),
    //     rentalDiscount: this.rentalDiscount,
    //     bailDiscount: this.bailDiscount,
    //     promotionDiscount: this.promotionDiscount,
    //     // deduction: 0,
    //     // deductionDate: "2019-07-14T09:40:20.607Z",
    //     // bailReturningAmount: 0,
    //     // bailReturningStatus: "string",
    //     // bailReturningDate: "2019-07-14T09:40:20.607Z",
    //     description: "",
    //     state: true,
    //     // createDateTime: "",
    //     createBy: this.currentUser,
    //     // updateDateTime: "",
    //     updateBy: this.currentUser
    //   };
    //   var grandup =
    //     this.grandTotal -
    //     (this.bailDiscount + this.rentalDiscount + this.promotionDiscount);
    //   console.log("grandup >>>> ", grandup);
    //   // >>>> START TO SAVE HEADER

    //   var responseOrder;
    //   try {
    //     responseOrder = await this.$http.put(
    //       this.$store.state.apiURL + "/api/orderheader/" + this.orderid,
    //       ord,
    //       this.$store.state.auth.apiHeader
    //     );
    //     if (responseOrder.status === 200) {
    //       this.orderHeader = await responseOrder.data;
    //     }
    //   } catch (error) {
    //     setTimeout(() => {
    //       this.$vs.loading.close();
    //     }, 100);
    //     this.noticeError(error);
    //   }

    //   console.log(responseOrder.data);

    //   // SAVE DETAIL
    //   var dlist = [];
    //   for (var d = 0; d < this.displayItems.length; d++) {
    //     var detail = {
    //       orderHeaderId: await this.orderHeader.orderHeaderId,
    //       branchId: this.currentBranchId,
    //       productItemId: this.displayItems[d].productItemId,
    //       code: this.displayItems[d].code,
    //       reservedQuantity: this.displayItems[d].reservedQuantity,
    //       rentalPrice: this.displayItems[d].rentalPrice,
    //       bail: this.displayItems[d].bail,
    //       state: true,
    //       startDate: this.startDate,
    //       endDate: this.endDate,
    //       createBy: this.currentUser,
    //       updateBy: this.currentUser
    //     };

    //     dlist.push(detail);
    //   }

    //   // >>>> START TO SAVE HEADER

    //   var responseDetail;
    //   try {
    //     responseDetail = await this.$http.put(
    //       this.$store.state.apiURL + "/api/orderdetail/" + this.orderid,
    //       dlist,
    //       this.$store.state.auth.apiHeader
    //     );
    //     if (responseDetail.status === 200) {
    //       setTimeout(() => {
    //         this.$vs.loading.close();
    //         this.$router.push({
    //           path: "/backoffice/order-detail/" + this.orderHeader.orderHeaderId
    //         });
    //       }, 500);
    //     }
    //   } catch (error) {
    //     setTimeout(() => {
    //       this.$vs.loading.close();
    //     }, 100);
    //     this.noticeError(error);
    //   }

    //   // >>>> START TO SAVE HEADER
    // },

    async loadOrder(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderheader/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadAllDetail() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async comeback(orderid) {
      // var order = await this.loadOrder(orderid);
      // var dlist = await this.loadDetail(orderid);

      var  apiUrl = "/api/orderheader/receipt-info/";

     let ord = null;

     let order = null;
     let dlist = null;

      var resp = null;

      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + apiUrl + orderid,
          {},
          this.$store.state.auth.apiHeader
        );
        ord = resp.data;
        order = ord.orderHeader;
        dlist = ord.orderDetail;

        // console.log("order >>> ",order);
        // console.log("orderDetail >>> ",orderDetail);


        // console.log("this.orderInfo >>> ",ord);
        // console.log("this.orderInfo.shopInfo >>> ", ord);

      } catch (err) {
        this.$swal({
          icon: 'warning',
          title: 'ไม่สามารถรับข้อมูลได้',
        });
      }


      await this.getCustomerInfo(order.customerPhone)



      if(this.rentalDate === null || this.rentalDate === undefined){
        this.rentalDate = {start: null , end : null}
      }



      // let pickupandreturn =  {start: new Date(order.pickupDate) , end : new Date(order.returnDate)} ;


      // this.rentalDate = pickupandreturn

      // console.log("$$$$$$$ Comeback this.rentalDate >>> ",this.rentalDate);


      this.orderNumber = await order.orderNumber;

      // this.rentalDate.start=  pickDate;
      // this.rentalDate["end"] =  returnDate;

      this.receivingMethod = await order.receivingMethod;
      this.startDate = await order.pickupDate;


      this.endDate = await order.returnDate;
      this.rentalPriceTotal = await order.rentalPriceTotal;
      this.bailTotal = await order.bailTotal;
      this.grandTotal = await order.grandTotal;
      this.rentalDiscount = await order.rentalDiscount;
      this.bailDiscount = await order.bailDiscount;
      this.promotionDiscount = await order.promotionDiscount;
      this.orderStatus = await order.orderStatus;
      this.currentBranchId = await order.branchId;
      this.remark1 = await order.remark1;
       ++(this.datecode);
      // * >>>>>>>>>>>>>> ORDER No. >>>>>>>>>>>>>>>>>>>>

      //*** 15May2022 */

      this.pickupMethod = await order.receivingMethod;
      this.rentalPaymentMethod =  await order.rentalPaymentMethod;
      this.bailPaymentMethod = await order.bailPaymentMethod;

    //  console.log('******* async startDate >>> ',this.startDate );
    //  console.log('******* async endDate >>> ',this.endDate );

      // this.$set(this.rentalDate, ind, item);

      this.order = order;


      // console.log('******* async comeback >>> ',this.rentalDate );


      var tmpno = this.orderNumber.split("-");

      var verno = Number(tmpno[tmpno.length - 1]);

      var ordernoSuffix = 2;

      if (isNaN(verno)) {
        ordernoSuffix = 2;
      } else {
        ordernoSuffix = verno + 1;
      }

      this.orderNumber = tmpno[0] + "-" + ordernoSuffix;

      // * >>>>>>>>>>>>>> ORDER No. >>>>>>>>>>>>>>>>>>>>

      // var thisBranch = await allBranches.find(
      //   a => a.branchId === this.currentBranchId
      // );
      await this.init(this.thisBranch);

      // LOAD DETAIL

      // console.log("@COMEBACK dlist >>> ", dlist);

      if (dlist !== null && dlist !== undefined && dlist.length > 0) {
        for (var d = 0; d < dlist.length; d++) {
          // var tempItem = await this.itemsInBranch.find(
          //   x => x.productItemId === dlist[d].productItemId
          // );


          // console.log("@COMEBACK dlist[",d,"] ", dlist[d]);
          // console.log("@COMEBACK tempItem ", tempItem);

          const anItem = Object.assign({}, dlist[d]);

          anItem.reservedQuantity = dlist[d].reservedQuantity;
          if(dlist[d].flag1 === null || dlist[d].flag1 === undefined || dlist[d].flag1 === "")
          {
            anItem.removed = "n";

          }else{
            anItem.removed = dlist[d].flag1;
          }
          // console.log("@COMEBACK dlist >>> productitem ", dlist[d].code , " FLAG1 >>> ",dlist[d].flag1,  );
          await this.displayItems2.push(anItem);
        }
      }

    //  console.log('******* async comeback end >>> ',this.startDate );
    //  console.log('******* async comeback end >>> ',this.endDate );

      // console.log("@COMEBACK itemsInBranch  >>> ", this.itemsInBranch);

      // console.log("@COMEBACK displayItems  >>> ", this.displayItems2);

      // await this.checkQueue();
    },

    async init(thisBranch) {
      this.displayItems = [];
      var allInBranch = await this.loadStock(thisBranch.branchId);
      this.branchStock = await allInBranch;


      // console.log(" branchStock >>>> ", this.branchStock);

      // LOAD ALL ITEMS
      var allProduct = await this.loadProduct();
      this.originalList = await allProduct;
      this.itemsInStock = await allProduct;

      // console.log(" <<<<< itemsInStock >>>> ", this.itemsInStock);

      // INITAILIZE ITEMS in BRANCH
      this.itemsInBranch = [];
      for (var i = 0; i < allInBranch.length; i++) {
        var anItem = await allProduct.find(
          x => x.productItemId === allInBranch[i].productItemId
        );

        // console.log("[", i, "]  >>>> anITEM >>>> ", anItem);

        if (anItem !== null && anItem !== undefined) {
          if (anItem.mainImage) {
            anItem.mainImage =
              (await this.$store.state.imageStore) + anItem.mainImage;
          } else {
            anItem.mainImage =
              (await this.$store.state.imageStore) + "defaultImage.png";
          }

          // ADDITIONAL FIELD
          anItem.amountInBranch = allInBranch[i].inStock;
          anItem.reservedQuantity = 1;
          anItem.notAvailable = false;
          anItem.category = anItem.category;

          this.itemsInBranch.push(anItem);
        }
      }

      // console.log("this.itemsInBranch >>>> ", this.itemsInBranch);
    },
    async findCurrentBranch() {
      // >>>>>>> หา branch จาก  role ของ user >>>>>>>>>
      let branch_th = "1";
      this.roles = JSON.parse(localStorage.getItem("roles"));
      var userrole = this.roles.find(x => x.roleName.includes("staff-"));
      if (userrole === null || userrole === undefined) {
        branch_th = "1";
      } else {
        var role_split = userrole.roleName.split("-");
        if (role_split[1] === null || role_split[1] === undefined) {
          branch_th = "1";
        }
        branch_th = role_split[1];
      }

      // console.log(" branch_th >>>> ", branch_th);

      var allBranches = await this.loadBranch();

      var thisBranch = await allBranches.find(a => a.code === branch_th);

      this.currentBranchId = await thisBranch.branchId;

      // console.log(" thisBranch >>>> ", thisBranch);

      return thisBranch;
    },
    async loadProduct() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadStock(branchId) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    async loadBranch() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
        // this.noticeSuccess("ทำการบันทึกสำเร็จ");
        if (response.status === 200) {
          this.branches = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        console.log("error", error);
      }

      return await response.data;
    },
    selectAll: function(event) {
      // Workaround for Safari bug
      // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(function() {
        event.target.select();
      }, 0);
    },
    computeBailDiscount(newValue) {
      if (
        this.bailTotal != 0 &&
        this.bailTotal != undefined &&
        this.bailTotal != null
      )
        this.bailDiscount = this.bailTotal * (newValue / 100);
    },
    computeRentalDiscount(newValue) {
      if (
        this.rentalPriceTotal != 0 &&
        this.rentalPriceTotal != undefined &&
        this.rentalPriceTotal != null
      )
        this.rentalDiscount = this.rentalPriceTotal * (newValue / 100);
    },
    computeDiscountPercent() {
      if (
        this.rentalPriceTotal === 0 ||
        this.rentalPriceTotal === undefined ||
        this.rentalPriceTotal === null
      )
        this.rentalDiscountPercent = 0;
      else
        this.rentalDiscountPercent =
          (this.rentalDiscount / this.rentalPriceTotal) * 100;

      if (
        this.bailTotal === 0 ||
        this.bailTotal === undefined ||
        this.bailTotal === null
      )
        this.bailDiscountPercent = 0;
      else
        this.bailDiscountPercent = (this.bailDiscount / this.bailTotal) * 100;
    },
    confirmDetail() {
      this.$router.push({
        path: "/backoffice/order-detail/" + this.orderInfo.orderNumber
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.checkRentalList = false;

      this.displayItems[indextr].reservedQuantity = 1;
      this.displayItems[indextr].notAvailable = false;
      this.displayItems.splice(indextr, 1);
      console.log(this.displayItems);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    },
    // **********************************************************
    // ****************** CALCULATE TOTAL ***********************
    // **********************************************************
    calculateTotal() {
      this.rentalPriceTotal = 0;
      this.bailTotal = 0;
      this.grandTotal = 0;
      var i = 0;
      for (i = 0; i < this.displayItems.length; i++) {
        this.rentalPriceTotal =
          this.rentalPriceTotal +
          this.displayItems[i].rentalPrice *
            this.displayItems[i].reservedQuantity;

        this.bailTotal =
          this.bailTotal +
          this.displayItems[i].bail * this.displayItems[i].reservedQuantity;
      }

      for (i = 0; i < this.displayItems2.length; i++) {
        if (this.displayItems2[i].removed === "n") {
          this.rentalPriceTotal =
            this.rentalPriceTotal +
            this.displayItems2[i].rentalPrice *
              this.displayItems2[i].reservedQuantity;

          this.bailTotal =
            this.bailTotal +
            this.displayItems2[i].bail * this.displayItems2[i].reservedQuantity;
        }
      }
      this.grandTotal =
        this.grandTotal + this.rentalPriceTotal + this.bailTotal;

      this.computeDiscountPercent();
      // **********************************************************
      this.calculatePromotion();
    }
    // **********************************************************
  },

  watch: {
    popupOnlineBillingActive(newvalue){
      if(newvalue == false){
        // this.$store.commit("cart/checkRentalListInput", false);
        // this.$store.commit("cart/activeBtnInput", false);
      }
        // console.log("WATCH:popupOnlineBillingActive ::: checkRentalList >>> ",this.checkRentalList , " activeBtn >>> ",this.activeBtn);

    },
    // branchRadio: async function(value) {
    //   this.$vs.loading({ type: "radius" });
    //   this.checkRentalList = false;
    //   this.startDate = null;
    //   this.endDate = null;
    //   var bra = this.branches.find(b => b.branchId === value);
    //   this.currentBranchId = value;
    //   // console.log(
    //   //   "Branch SELECTED >>> ",
    //   //   bra.branchName,
    //   //   " NOW BRANCH  this.currentBranchId >>> ",
    //   //   this.currentBranchId
    //   // );

    //   await this.init(bra);
    //   setTimeout(() => {
    //     this.$vs.loading.close();
    //   }, 300);
    // },
    rentalDate: {
      handler(value) {
        // console.log("@@@rentalDate >>>>  ", value);
        ++(this.datecode);
        // console.log("@@@ datecode >>>>  ", this.datecode);
        let the_value = Object.assign({},value )
        if (Object.entries(the_value).length === 0 ) {
          // console.log("WATCH RENTALDATE IF ");
          this.startDate = null;
          this.endDate = null;
        } else {
          // console.log("WATCH RENTALDATE ELSE ");
          this.startDate = this.formatDate(value.start);
          this.endDate = this.formatDate(value.end);
        }

        // console.log("@@@ WATCH this.startDate >>>>  ", this.startDate);
        // console.log("@@@ WATCH this.endDate >>>>  ", this.endDate);
        // console.log("@@@ WATCH value of  rentalDate >>>>  ", value);
      },
      deep: true
    },
    startDate: function() {
      // this.endDate = value;
      this.checkRentalList = false;

    },
    endDate: function() {
      this.checkRentalList = false;
    },
    customerInfo: function(newphone) {
      var ac = this.customers.find(x => x.phone.includes(newphone));
      this.customerName = ac.name;
      this.customerAddress = ac.address;
      this.customerPhone = ac.phone;
      // console.log("this.customers.customerName; >>>> ", ac.customerName);
    },
    // bailDiscount: function(newValue) {
    //   if (
    //     this.bailTotal != 0 &&
    //     this.bailTotal != undefined &&
    //     this.bailTotal != null
    //   )
    //     this.bailDiscount = this.bailTotal * (newValue / 100);
    // },
    // rentalDiscount: function(newValue) {
    //   if (
    //     this.rentalPriceTotal != 0 &&
    //     this.rentalPriceTotal != undefined &&
    //     this.rentalPriceTotal != null
    //   )
    //     this.rentalDiscount = this.rentalPriceTotal * (newValue / 100);
    // },
    rentalDiscount: function() {
      this.computeDiscountPercent();
    },
    bailDiscount: function() {
      this.computeDiscountPercent();
    },
    addtocart: function(newvalue) {
      this.checkRentalList = false;
      // *** BEGIN 2021-01-19
      this.activeBtn = false;
      // *** END 2021-01-19
      var newItem = this.itemsInBranch.find(function(ele) {
        if (ele.code === newvalue) {
          return ele;
        }
      });

      // console.log("newItem >>> ", newItem);

      if (newItem) {
        event.target.setSelectionRange(0, this.addtocart.length);

        var foundindex = this.displayItems.findIndex(
          x => x.code === newItem.code
        );

        if (foundindex < 0) this.displayItems.push(newItem);
        else {
          this.displayItems[foundindex].reservedQuantity++;
          this.calculateTotal();
        }


        this.addtocart = "";
      }
    },

    displayItems: {
      handler() {
        this.calculateTotal();
        this.checkRentalList = false;
        // console.log(' @@@@@@@@ WATCH @@@@@@@@ this.displayItems ',this.displayItems);

      },
      deep: true
    },
    displayItems2: {
      handler() {
        this.calculateTotal();
        this.checkRentalList = false;
      },
      deep: true
    }
  }
};
</script>

<style>
.cal input{
  padding : 0 !important;
}
input {
  width: 100%;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}
.clear {
  display: none;
}

.notAvailable {
  background: #e95455;
  border: 1px solid #9e0619;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
</style>
